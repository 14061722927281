import { ParsedUrlQuery } from "querystring";

import { StoryData, StoryblokBlokData } from "@storyblok/react";
import { StoryblokSeo } from "storyblok/components";

interface StoryblokPageData {
    /** All blocks defined in the body of a storyblok story */
    blocks: Array<StoryblokBlokData> | null;
    /** Seo data set by the Storyblok SEO plugin  */
    seo: StoryblokSeo | null;
}

/**
 * Filter only seo and content blocks from the getStory() API response
 * @param storyData StoryData object returned from the API
 * @returns {StoryblokPageData} body blocks & seo data if returned by the API
 */
export function getStoryblokPageData(storyData: StoryData): StoryblokPageData {
    const { content } = storyData;
    const seo = (content?.seo as StoryblokSeo) || null;
    const blocks = (content?.body as Array<StoryblokBlokData>) || null;

    return {
        blocks,
        seo
    };
}

/**
 * Since params from GetStaticPropsContext can be undefined, a string or an array of strings we need to format this to a string to be able to pass this to the API
 * @param params string || string[], Query params passed from url path
 * @returns {string} A string of the slug to pass to the Storyblok API
 */
export function getSlugFromParams(params?: ParsedUrlQuery): string {
    if (params?.slug) {
        return Array.isArray(params.slug) ? params.slug.join("/") : params.slug;
    }

    return "";
}
